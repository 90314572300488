class EventoService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    guardar: {
      uri: `/v1/eventos/guardar`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    editar: {
      uri: `/v1/eventos/editar`,
      method: ['put']
    },
    eventos: {
      uri: `/v1/calendario/eventos`,
      method: ['get']
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/eventos`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    eliminarevento: {
      uri: `/v1/evento/eliminar`,
      method: ['delete']
    }
  });
}

export default EventoService;
